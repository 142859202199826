import { clinics } from "../../js/path";

export default {
  data() {
    return {
      image_name: "",
      image_name_url: "",
      edit: {
        image_name_url: "",
      },
      pageNo: null,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      currentPage: 1,
      params: "",
      key: 0,
      clinic_gallery: [],
      clinic_gallery_fields: [
        {
          key: "id",
        },
        {
          key: "image_name",
          label: "Image",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      form: {
        clinic_gallery: [{ id: 0, image: null, is_active: 0, previewUrl: "" }],
      },
      updateForm: {
        is_active: 0,
      },
    };
  },
  methods: {
    readFile(e, txt) {
      if (txt == "image_name") {
        this.image_name = e.target.files[0];
        if (this.image_name.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.image_name.reset();
          this.image_name_url = "";
          this.edit.image_name_url = "";
          this.image_name = "";
          return false;
        } else {
          this.image_name_url = URL.createObjectURL(this.image_name);
          this.edit.image_name_url = URL.createObjectURL(this.image_name);
        }
        return true;
      }
    },
    async deleteGallery(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.clinic_gallery.findIndex((e) => e.id == id);
        const url = `${clinics?.getClinicGallery}/${id}`;
        const res = await this.postRequest(url, {
          _method: "delete",
        });
        if (res.status) {
          this.clinic_gallery.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: res.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.clinic_gallery.findIndex((e) => e.id === id);
        const url = `${clinics?.updateGalleryStatus}/${id}`;
        const data = await this.postRequest(url, {
          is_active: !this.clinic_gallery[index].is_active,
        });
        if (data.status) {
          this.clinic_gallery[index].is_active =
            !this.clinic_gallery[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchGalleryList() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getClinicGallery}/${this.$route.params.id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          this.clinic_gallery = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    addImage() {
      this.form.clinic_gallery.push({
        id: this.form.clinic_gallery.length,
        image: "",
        is_active: 0,
        previewUrl: "",
      });
    },
    removeImage(index) {
      if (this.form.clinic_gallery.length > 1) {
        this.form.clinic_gallery.splice(index, 1);
      }
    },
    handleFileChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded file is more than 2MB",
          });
          event.target.value = "";
          return;
        }
        this.$set(this.form.clinic_gallery, index, {
          id: this.form.clinic_gallery[index].id,
          image: file,
          is_active: this.form.clinic_gallery[index].is_active,
          previewUrl: URL.createObjectURL(file),
        });
      }
    },
    async fetchSingleHospital() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getSingleGallery}/${
          this.$route.query?.hospitalId ?? ""
        }`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData) {
            this.updateForm.is_active =
              responseData.is_active == 1 ? true : false;
            this.edit.image_name_url = responseData.image_name;
          }
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      if (this.$route.name == "add-clinic-gallery" && this.$v.$invalid) {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = clinics?.getClinicGallery;
        if (this.$route.name === "edit-clinic-gallery") {
          url = `${clinics?.getClinicGallery}/${
            this.$route?.query?.hospitalId ?? ""
          }`;
        }
        let dataAppend = new FormData();
        if (this.$route.name === "edit-clinic-gallery") {
          if (this.image_name) {
            dataAppend.append("image", this.image_name);
          }
          dataAppend.append(
            "is_active",
            this.updateForm.is_active == true ? 1 : 0
          );
        }
        if (this.$route.name == "add-clinic-gallery") {
          this.form.clinic_gallery.forEach((item, index) => {
            if (item.image) {
              dataAppend.append(`clinic_gallery[${index}][id]`, item.id);
              dataAppend.append(`clinic_gallery[${index}][image]`, item.image);
              dataAppend.append(
                `clinic_gallery[${index}][is_active]`,
                item.is_active === true ? 1 : 0
              );
            }
          });
        }
        dataAppend.append("clinic_id", this.$route.params.id);
        if (this.$route.name === "edit-clinic-gallery") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push(`/clinic/edit-clinic/${this.$route.params.id}`);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
};
