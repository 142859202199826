<template>
  <Layout>
    <PageHeader title="Edit Gallery" :items="items"> </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <b-form-group>
                <label>Clinic Image <span style="color: red">*</span></label>
                <div class="row pl-2">
                  <div class="col-7 pl-0">
                    <b-form-file
                      accept=".jpg,.png,.svg"
                      @change="readFile($event, 'image_name')"
                      ref="image_image"
                      placeholder="Choose a file or drop it here"
                    >
                    </b-form-file>
                    <template
                      v-if="
                        $route.name == 'edit-clinic-gallery' &&
                        edit.image_name_url
                      "
                    >
                      <img
                        :src="edit.image_name_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </div>
                  <b-form-group class="col-1 d-flex align-items-start mt-2">
                    <b-form-checkbox v-model="updateForm.is_active">
                      Active
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
                >Update Data
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
import hospitalMixin from "@/mixins/ModuleJs/clinic-gallery";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, hospitalMixin],
  data() {
    return {
      submitted: false,
      items: [
        {
          text: "Back",
          href: `/clinic/edit-clinic/${this.$route.params.id}`,
        },
        {
          text: "Data",
        },
      ],
    };
  },
  created() {
    this.fetchSingleHospital(this.$route.params.id);
  },
};
</script>
